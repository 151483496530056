<script setup>
import { computed, ref, onBeforeUnmount } from 'vue';
import { useListingStore } from '~/stores/account/listings.store';

const listingsStore = useListingStore();

const allCategories = computed(() => listingsStore.categoriesGetter());
const displayedCategories = ref(allCategories.value);

const activeCategoryId = ref(null);

const reset = () => {
    activeCategoryId.value = null
    listingsStore.updateSelectedForm('category_id', null);
    listingsStore.deleteAddListingForm();
}

onBeforeUnmount(() => {
    reset()
});

await listingsStore.fetchCategories();

const onSelectCategory = async (id) => {
    const selectedCategory = displayedCategories.value.find(c => c.id == id);
    if (selectedCategory?.children?.length) {
        displayedCategories.value = selectedCategory.children;
    } else {
        if (activeCategoryId.value != id) {
            activeCategoryId.value = id;
            listingsStore.updateSelectedForm('category_id', id);
            await listingsStore.fetchAddListingForm(id);
        }
    }
};

const isActiveCategory = (id) => {
    return activeCategoryId.value === id;
};

const onBack = () => {
    displayedCategories.value = allCategories.value;
    reset()
};
</script>

<template>
    <div class="border-b my-3">
        <div class="flex items-center">
            <span class="m-2" @click="onBack">
                <i class="fa-solid fa-chevron-left fa-lg m-0 p-0"></i>
            </span>
            <h3 class="text-lg font-medium text-indigo-900 leading-normal">{{ $t('client.category') }} *</h3>
        </div>
        <div class="w-full flex gap-2 snap-x overflow-x-auto my-3">
            <a v-for="(c, i) in displayedCategories" :key="i" class="snap-start shrink-0 relative">
                <div 
                    @click="onSelectCategory(c.id)" 
                    class="rounded-2xl p-3 block w-24 active-type"
                    :class="isActiveCategory(c.id) ? 'bg-indigo-900 text-white' : 'bg-white'"
                >
                    <div class="flex flex-col justify-center items-center">
                        <i :class="c.icon"></i>
                        <h4 class="mt-3 text-sm">{{ c.name }}</h4>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>
